/*
 * Copyright 2022 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Platform } from "react-native";
/**
 * Class that is used to provide biomety configuration for `PowerAuth` class.
 */
var PowerAuthBiometryConfiguration = /** @class */ (function () {
    /**
     * The default class constructor, respecting a platform specific differences.
     */
    function PowerAuthBiometryConfiguration() {
        var d = buildBiometryConfiguration();
        this.linkItemsToCurrentSet = d.linkItemsToCurrentSet;
        this.fallbackToDevicePasscode = d.fallbackToDevicePasscode;
        this.confirmBiometricAuthentication = d.confirmBiometricAuthentication;
        this.authenticateOnBiometricKeySetup = d.authenticateOnBiometricKeySetup;
    }
    /**
     * @returns `PowerAuthBiometryConfiguration` with default configuration.
     */
    PowerAuthBiometryConfiguration.default = function () {
        return buildBiometryConfiguration();
    };
    return PowerAuthBiometryConfiguration;
}());
export { PowerAuthBiometryConfiguration };
/**
 * Function create a frozen object implementing `BiometryConfigurationType` with all properties set.
 * @param input Optional application's configuration. If not provided, then the default values are set.
 * @returns Frozen `BiometryConfigurationType` interface with all properties set.
 */
export function buildBiometryConfiguration(input) {
    var _a, _b, _c, _d;
    if (input === void 0) { input = undefined; }
    return Object.freeze({
        // The following platform switch is required due to fact that the native SDK has by default a different
        // configuration for this attribute. This was not configurable in the previous version of RN wrapper, 
        // so the old behavior must be emulated. If we enforce true or false, then app developers may encounter 
        // a weird behavior after the library update.
        linkItemsToCurrentSet: (_a = input === null || input === void 0 ? void 0 : input.linkItemsToCurrentSet) !== null && _a !== void 0 ? _a : Platform.OS === 'android',
        fallbackToDevicePasscode: (_b = input === null || input === void 0 ? void 0 : input.fallbackToDevicePasscode) !== null && _b !== void 0 ? _b : false,
        confirmBiometricAuthentication: (_c = input === null || input === void 0 ? void 0 : input.confirmBiometricAuthentication) !== null && _c !== void 0 ? _c : false,
        authenticateOnBiometricKeySetup: (_d = input === null || input === void 0 ? void 0 : input.authenticateOnBiometricKeySetup) !== null && _d !== void 0 ? _d : true
    });
}
