/*
 * Copyright 2022 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Class that is used to provide RESTful API client configuration.
 */
var PowerAuthClientConfiguration = /** @class */ (function () {
    function PowerAuthClientConfiguration() {
        var d = buildClientConfiguration();
        this.enableUnsecureTraffic = d.enableUnsecureTraffic;
        this.connectionTimeout = d.connectionTimeout;
        this.readTimeout = d.readTimeout;
    }
    /**
     * @returns `PowerAuthClientConfiguration` with default values.
     */
    PowerAuthClientConfiguration.default = function () {
        return buildClientConfiguration();
    };
    return PowerAuthClientConfiguration;
}());
export { PowerAuthClientConfiguration };
/**
 * Function create a frozen object implementing `ClientConfigurationType` with all properties set.
 * @param input Optional application's configuration. If not provided, then the default values are set.
 * @returns Frozen `ClientConfigurationType` interface with all properties set.
 */
export function buildClientConfiguration(input) {
    var _a, _b, _c;
    if (input === void 0) { input = undefined; }
    return Object.freeze({
        enableUnsecureTraffic: (_a = input === null || input === void 0 ? void 0 : input.enableUnsecureTraffic) !== null && _a !== void 0 ? _a : false,
        connectionTimeout: (_b = input === null || input === void 0 ? void 0 : input.connectionTimeout) !== null && _b !== void 0 ? _b : 20.0,
        readTimeout: (_c = input === null || input === void 0 ? void 0 : input.readTimeout) !== null && _c !== void 0 ? _c : 20.0
    });
}
