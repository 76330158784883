//
// Copyright 2022 Wultra s.r.o.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PowerAuthError } from "../index";
import { NativeWrapper } from "../internal/NativeWrapper";
import { NativeObjectRegister } from "./NativeObjectRegister";
/**
 * The `PowerAuthDebug` class provides a various functionality that can
 * help application develoepr with debugging the problem with React Native PowerAuth mobile SDK.
 */
var PowerAuthDebug = /** @class */ (function () {
    function PowerAuthDebug() {
    }
    /**
     * Enable or disable detailed log with calls to native code. Be aware that this feature is
     * effective only if global __DEV__ constant is `true`.
     * @param traceFailure If set to `true`, then SDK will print a detailed error if native call fails.
     * @param traceEachCall If set to `true`, then SDK will print a detailed information about each call to the native code.
     */
    PowerAuthDebug.traceNativeCodeCalls = function (traceFailure, traceEachCall) {
        if (traceEachCall === void 0) { traceEachCall = false; }
        if (__DEV__) {
            NativeWrapper.setDebugFeatures(traceFailure, traceEachCall);
        }
    };
    /**
     * Function prints debug information about all native objects registered in native module. Note that the function
     * is effective ony if native module is compiled in DEBUG mode and if global `__DEV__` constant is `true`.
     * @param instanceId If provided, then prints only objects that belongs to PowerAuth instance with given identifier.
     */
    PowerAuthDebug.dumpNativeObjects = function (instanceId) {
        if (instanceId === void 0) { instanceId = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            var printTag, objectInfo, maxLenId_1, maxLenTag_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!__DEV__) return [3 /*break*/, 2];
                        if (instanceId) {
                            console.log("List of native objects associated with instance '".concat(instanceId, "' = ["));
                        }
                        else {
                            console.log('List of all registered native objects = [');
                        }
                        printTag = instanceId ? false : true;
                        return [4 /*yield*/, NativeObjectRegister.debugDump(instanceId)];
                    case 1:
                        objectInfo = _a.sent();
                        maxLenId_1 = objectInfo.reduce(function (prev, item) { return Math.max(prev, item.id.length); }, 0);
                        maxLenTag_1 = printTag ? objectInfo.reduce(function (prev, item) { var _a, _b; return Math.max(prev, (_b = (_a = item.tag) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0); }, 0) : 0;
                        objectInfo.forEach(function (item) {
                            var created = new Date(item.createDate * 1000);
                            var used = item.lastUseDate ? ", lastUsed='".concat(new Date(item.lastUseDate * 1000), "'") : '';
                            var count = item.usageCount ? ", used=".concat(item.usageCount) : '';
                            var valid = item.isValid ? '   ' : '!! ';
                            var tag = item.tag && !instanceId ? " @ ".concat(item.tag.padEnd(maxLenTag_1)) : '';
                            var policies = item.policies.join(', ');
                            var objId = "".concat(item.id).padEnd(maxLenId_1);
                            console.log("  ".concat(valid).concat(objId, " ").concat(tag, " = { ").concat(item.class, ", ").concat(policies, ", created='").concat(created, "'").concat(used).concat(count, " }"));
                        });
                        console.log(']');
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Function converts any error into human readable string.
     * @param error Error to descrive.
     * @returns Error translated to human readable string.
     */
    PowerAuthDebug.describeError = function (error) {
        if (typeof error === 'string') {
            return error;
        }
        if (error instanceof PowerAuthError) {
            var components = ['PowerAuthError'];
            if (error.code)
                components.push(error.code);
            if (error.message)
                components.push(error.message);
            if (error.originalException)
                components.push("Reason = { ".concat(this.describeError(error.originalException), " }"));
            return components.join(': ');
        }
        if (error instanceof Error) {
            return "".concat(error.name, ": ").concat(error.message);
        }
        return "".concat(error);
    };
    return PowerAuthDebug;
}());
export { PowerAuthDebug };
