/*
 * Copyright 2022 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * ### Android specific
 *
 * The `PowerAuthKeychainProtection` enum defines the level of keycain content protection.
 * The level of the protection depends on Android KeyStore implementation available on the device.
 * If the KeyStore supports hardware backed keys, like StrongBox, then also the higher level of
 * protection is reported.
 */
export var PowerAuthKeychainProtection;
(function (PowerAuthKeychainProtection) {
    /**
     * The content of the keychain is not encrypted and therefore not protected. This level of
     * the protection is typically reported on devices older than Android Marshmallow, or in
     * case that the device has faulty KeyStore implementation.
     */
    PowerAuthKeychainProtection["NONE"] = "NONE";
    /**
     * The content of the keychain is encrypted with key generated by Android KeyStore, but the key
     * is protected only on the operating system level. The security of the key material relies solely
     * on software measures, which means that a compromise of the Android OS (such as root exploit)
     * might up revealing this key.
     *
     * If this level of protection is enforced in `PowerAuthKeychainConfiguration`, then your application
     * must target Android 6.0 and higher.
     */
    PowerAuthKeychainProtection["SOFTWARE"] = "SOFTWARE";
    /**
     * The content of the keychain is encrypted with key generated by Android KeyStore and the key
     * is stored and managed by [Trusted Execution Environment](https://en.wikipedia.org/wiki/Trusted_execution_environment).
     */
    PowerAuthKeychainProtection["HARDWARE"] = "HARDWARE";
    /**
     * The content of the keychain is encrypted with key generated by Android KeyStore and the key
     * is stored inside of Secure Element (e.g. StrongBox). This is the highest level of Keychain
     * protection currently available.
     *
     * Be aware, that enforce STRONGBOX is not recommended due to its low reliabiliy and low performance
     * on the current Android devices.
     */
    PowerAuthKeychainProtection["STRONGBOX"] = "STRONGBOX";
})(PowerAuthKeychainProtection || (PowerAuthKeychainProtection = {}));
/**
 * Class representing the keychain settings.
 */
var PowerAuthKeychainConfiguration = /** @class */ (function () {
    function PowerAuthKeychainConfiguration() {
        this.minimalRequiredKeychainProtection = buildKeychainConfiguration().minimalRequiredKeychainProtection;
    }
    /**
     * @returns `PowerAuthKeychainConfiguration` with default values.
     */
    PowerAuthKeychainConfiguration.default = function () {
        return buildKeychainConfiguration();
    };
    return PowerAuthKeychainConfiguration;
}());
export { PowerAuthKeychainConfiguration };
/**
 * Function create a frozen object implementing `KeychainConfigurationData` with all required properties set.
 * @param input Optional application's configuration. If not provided, then the default values are set.
 * @returns
 */
export function buildKeychainConfiguration(input) {
    var _a;
    if (input === void 0) { input = undefined; }
    return Object.freeze({
        accessGroupName: input === null || input === void 0 ? void 0 : input.accessGroupName,
        userDefaultsSuiteName: input === null || input === void 0 ? void 0 : input.userDefaultsSuiteName,
        minimalRequiredKeychainProtection: (_a = input === null || input === void 0 ? void 0 : input.minimalRequiredKeychainProtection) !== null && _a !== void 0 ? _a : PowerAuthKeychainProtection.NONE
    });
}
