//
// Copyright 2022 Wultra s.r.o.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NativeWrapper } from './NativeWrapper';
import { NativeObject } from './NativeObject';
/**
 * The `AuthResolver` helper class hides internal dependencies when PowerAuthAuthentication needs to be
 * resolved in case of biometry factor is used.
 */
var AuthResolver = /** @class */ (function () {
    /**
     * Construct resolver with required instance identifier.
     * @param instanceId PowerAuth instnace identifier.
     */
    function AuthResolver(instanceId) {
        this.instanceId = instanceId;
    }
    /**
     * Method will process `PowerAuthAuthentication` object are will return object according to the platform.
     * The method should be used only for the signing purposes.
     * @param authentication Authentication configuration
     * @param makeReusable if the object should be forced to be reusable
     * @returns configured authorization object
     */
    AuthResolver.prototype.resolve = function (authentication, makeReusable) {
        if (makeReusable === void 0) { makeReusable = false; }
        return __awaiter(this, void 0, void 0, function () {
            var correctAuth, privateAuth, isReusable, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        correctAuth = authentication.convertLegacyObject(false);
                        privateAuth = correctAuth;
                        if (!(privateAuth.isReusable && privateAuth.biometryKeyId !== undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, NativeObject.isValidNativeObject(privateAuth.biometryKeyId)];
                    case 1:
                        if (!(_b.sent())) {
                            privateAuth.biometryKeyId = undefined;
                        }
                        _b.label = 2;
                    case 2:
                        if (!(privateAuth.isBiometry && privateAuth.biometryKeyId === undefined)) return [3 /*break*/, 6];
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        isReusable = privateAuth.isReusable = privateAuth.isReusable || makeReusable;
                        // Acquire biometry key. The function returns ID to underlying data object with a limited validity.
                        _a = privateAuth;
                        return [4 /*yield*/, NativeWrapper.thisCall('authenticateWithBiometry', this.instanceId, correctAuth.biometricPrompt, isReusable)];
                    case 4:
                        // Acquire biometry key. The function returns ID to underlying data object with a limited validity.
                        _a.biometryKeyId = (_b.sent());
                        return [2 /*return*/, correctAuth];
                    case 5:
                        e_1 = _b.sent();
                        throw NativeWrapper.processException(e_1);
                    case 6: 
                    // no other processing is required
                    return [2 /*return*/, correctAuth];
                }
            });
        });
    };
    return AuthResolver;
}());
export { AuthResolver };
