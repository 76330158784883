/*
 * Copyright 2021 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Class representing a multi-factor authentication object.
 */
var PowerAuthAuthentication = /** @class */ (function () {
    /**
     * Construct authentication object with combination of factors.
     * @deprecated Please use static methods to create `PowerAuthAuthentication` object.
     * @param password Password to be used for knowledge factor, or undefined if knowledge factor should not be used.
     * @param biometricPrompt If set, then the biometry factor will be used for the authentication.
     */
    function PowerAuthAuthentication(password, biometricPrompt) {
        if (password === void 0) { password = undefined; }
        if (biometricPrompt === void 0) { biometricPrompt = undefined; }
        // Deprecated public properties.
        /**
         * Indicates if a possession factor should be used. The value should be always true.
         * @deprecated Direct access to property is now deprecated, use new static methods to construct `PowerAuthAuthentication` object.
         */
        this.usePossession = true;
        /**
         * Indicates if a biometry factor should be used.
         * @deprecated Direct access to property is now deprecated, use new static methods to construct `PowerAuthAuthentication` object.
         */
        this.useBiometry = false;
        this.password = password;
        this.biometricPrompt = biometricPrompt;
        this.isBiometry = biometricPrompt !== undefined;
        this.isReusable = false;
    }
    Object.defineProperty(PowerAuthAuthentication.prototype, "isActivationCommit", {
        /**
         * Indicates that this authentication object should be used for activation commit.
         */
        get: function () {
            var _a;
            return (_a = this.isCommit) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PowerAuthAuthentication.prototype, "isBiometricAuthentication", {
        /**
         * Indicates that this authentication object is for biometric authentication.
         */
        get: function () {
            return this.isBiometry || this.useBiometry;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Create object configured to authenticate with possession factor only.
     * @returns Authentication object configured for authentication with possession factor only.
     */
    PowerAuthAuthentication.possession = function () {
        return new PowerAuthAuthentication(undefined, undefined).configure(false);
    };
    /**
     * Create object configured to authenticate with combination of possession and biometry factors.
     * @param biometricPrompt Prompt to be displayed.
     * @returns Authentication object configured to authenticate with possession and biometry factors.
     */
    PowerAuthAuthentication.biometry = function (biometricPrompt) {
        return new PowerAuthAuthentication(undefined, biometricPrompt !== null && biometricPrompt !== void 0 ? biometricPrompt : FALLBACK_PROMPT).configure(false, true);
    };
    /**
     * Create object configured to authenticate with combination of possession and knowledge factors.
     * @param password User's password.
     * @returns Authentication object configured to authenticate with possession and knowledge factors.
     */
    PowerAuthAuthentication.password = function (password) {
        return new PowerAuthAuthentication(password, undefined).configure(false);
    };
    /**
     * Create object configured to commit activation with password.
     * @param password User's password. You can provide string or `PowerAuthPassword` object.
     * @returns Object configured to commit activation with password.
     */
    PowerAuthAuthentication.commitWithPassword = function (password) {
        return new PowerAuthAuthentication(password, undefined).configure(true);
    };
    /**
     * Create object configured to commit activation with password and biometry.
     * @param password User's password. You can provide string or `PowerAuthPassword` object.
     * @param biometricPrompt Required on Android, only when biometry config has `authenticateOnBiometricKeySetup` set to `true`.
     * @returns Object configured to commit activation with password and biometry.
     */
    PowerAuthAuthentication.commitWithPasswordAndBiometry = function (password, biometricPrompt) {
        if (biometricPrompt === void 0) { biometricPrompt = undefined; }
        return new PowerAuthAuthentication(password, biometricPrompt).configure(true, true);
    };
    // Private implementation
    /**
     * Configure object after its construction. The method is private, because we don't want to expose internal flags to
     * application developers. We'll fix this once we remove the deprecated properties in the next major release.
     * @param commit Value for isCommit property.
     * @param biometry Value for isBiometry property.
     * @param reusable Value for isReusable property. If not provided, then false is used.
     * @returns this
     */
    PowerAuthAuthentication.prototype.configure = function (commit, biometry, reusable) {
        if (biometry === void 0) { biometry = false; }
        if (reusable === void 0) { reusable = false; }
        this.isCommit = commit;
        this.isBiometry = biometry;
        this.isReusable = reusable;
        return this;
    };
    /**
     * Construct `PowerAuthBiometricPrompt` object from data available in this authentication object.
     * This is a temporary solution for compatibility with older apps that still use old way of authentication setup.
     * @returns PowerAuthBiometricPrompt object.
     */
    PowerAuthAuthentication.prototype.getBiometricPrompt = function () {
        var _a, _b;
        if (this.biometricPrompt) {
            return this.biometricPrompt;
        }
        // Authentication object was constructed in legacy mode,
        // so create a fallback object.
        return {
            promptMessage: (_a = this.biometryMessage) !== null && _a !== void 0 ? _a : FALLBACK_MESSAGE,
            promptTitle: (_b = this.biometryTitle) !== null && _b !== void 0 ? _b : FALLBACK_TITLE
        };
    };
    /**
     * If required, then converts a legacy constructed authentication object into
     * object supporting new properties introduced in version 2.3.0. This is a temporary
     * solution to achieve a compatibility with older apps that still use old way of
     * authentication setup.
     *
     * > Do not use this function in application code.
     *
     * @param forCommit If true, this conversion is for activation commit purposes.
     * @returns New authentication object created from the legacy properties, otherwise `this`.
     */
    PowerAuthAuthentication.prototype.convertLegacyObject = function (forCommit) {
        if (this.isCommit === undefined) {
            // This is a legacy object, so we have to create a new one to make sure that
            // the native code will reach to all new properties.
            var prompt = this.useBiometry ? this.getBiometricPrompt() : undefined;
            return new PowerAuthAuthentication(this.userPassword, prompt)
                .configure(forCommit, this.useBiometry, this.isReusable);
        }
        return this;
    };
    /**
     * Function convert authentication object into immutable object that can be passed to the natrive bridge.
     * You suppose not use this function in the application code.
     *
     * @returns Frozen object with data for authentication.
     */
    PowerAuthAuthentication.prototype.toRawAuthentication = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rawPassword, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.password !== undefined)) return [3 /*break*/, 4];
                        if (!(typeof this.password === 'string')) return [3 /*break*/, 1];
                        _b = this.password;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.password.toRawPassword()];
                    case 2:
                        _b = _c.sent();
                        _c.label = 3;
                    case 3:
                        _a = (_b);
                        return [3 /*break*/, 5];
                    case 4:
                        _a = undefined;
                        _c.label = 5;
                    case 5:
                        rawPassword = _a;
                        return [2 /*return*/, Object.freeze({
                                password: rawPassword,
                                biometricPrompt: this.biometricPrompt,
                                biometryKeyId: this.biometryKeyId,
                                isCommit: this.isCommit,
                                isReusable: this.isReusable,
                                isBiometry: this.isBiometry,
                            })];
                }
            });
        });
    };
    return PowerAuthAuthentication;
}());
export { PowerAuthAuthentication };
// Fallback strings
var FALLBACK_TITLE = '< missing title >';
var FALLBACK_MESSAGE = '< missing message >';
var FALLBACK_PROMPT = {
    promptMessage: FALLBACK_MESSAGE,
    promptTitle: FALLBACK_TITLE
};
