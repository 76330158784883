/*
 * Copyright 2021 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { NativeModules, Platform } from 'react-native';
import { PowerAuthError } from '../model/PowerAuthError';
import { PowerAuthDebug } from '../debug/PowerAuthDebug';
import { PowerAuthAuthentication } from '../index';
/**
 * The default `StaticCallTrampoline` implementation with no debug features supported.
 */
var DefaultStaticCall = /** @class */ (function () {
    function DefaultStaticCall() {
    }
    DefaultStaticCall.prototype.call = function (name, args) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, (NativeModules.PowerAuth[name].apply(null, args))];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        e_1 = _a.sent();
                        throw NativeWrapper.processException(e_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return DefaultStaticCall;
}());
/**
 * The default `ThisCallTrampoline` implementation with no debug features supported.
 */
var DefaultThisCall = /** @class */ (function () {
    function DefaultThisCall() {
    }
    DefaultThisCall.prototype.call = function (name, instanceId, args) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, (NativeModules.PowerAuth[name].apply(null, __spreadArray([instanceId], args, true)))];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        e_2 = _a.sent();
                        throw NativeWrapper.processException(e_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return DefaultThisCall;
}());
/**
 * The `StaticCallTrampoline` implementation that supports debug features.
 */
var DebugStaticCall = /** @class */ (function () {
    function DebugStaticCall(traceCall, traceFail) {
        this.traceCall = traceCall;
        this.traceFail = traceFail;
    }
    DebugStaticCall.prototype.call = function (name, args) {
        return __awaiter(this, void 0, void 0, function () {
            var msg, r, e_3, te;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = this.traceCall || this.traceFail ? "PowerAuth.".concat(name, "(").concat(prettyArgs(name, args), ")") : undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (this.traceCall) {
                            console.log("call ".concat(msg));
                        }
                        return [4 /*yield*/, (NativeModules.PowerAuth[name].apply(null, args))];
                    case 2:
                        r = _a.sent();
                        if (this.traceCall) {
                            console.log(" ret ".concat(msg, " => ").concat(JSON.stringify(r)));
                        }
                        return [2 /*return*/, r];
                    case 3:
                        e_3 = _a.sent();
                        te = NativeWrapper.processException(e_3);
                        if (this.traceFail) {
                            console.error("fail ".concat(msg, " => ").concat(PowerAuthDebug.describeError(te)));
                        }
                        throw te;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DebugStaticCall;
}());
/**
 * The `ThisCallTrampoline` implementation that supports debug features.
 */
var DebugThisCall = /** @class */ (function () {
    function DebugThisCall(traceCall, traceFail) {
        this.traceCall = traceCall;
        this.traceFail = traceFail;
    }
    DebugThisCall.prototype.call = function (name, instanceId, args) {
        return __awaiter(this, void 0, void 0, function () {
            var msg, r, e_4, te;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = this.traceCall || this.traceFail ? "PowerAuth.".concat(name, "(").concat(prettyArgs(name, __spreadArray([instanceId], args, true)), ")") : undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (this.traceCall) {
                            console.log("call ".concat(msg));
                        }
                        return [4 /*yield*/, (NativeModules.PowerAuth[name].apply(null, __spreadArray([instanceId], args, true)))];
                    case 2:
                        r = _a.sent();
                        if (this.traceCall) {
                            console.log(" ret ".concat(msg, " => ").concat(JSON.stringify(r)));
                        }
                        return [2 /*return*/, r];
                    case 3:
                        e_4 = _a.sent();
                        te = NativeWrapper.processException(e_4);
                        if (this.traceFail) {
                            console.error("fail ".concat(msg, " => ").concat(PowerAuthDebug.describeError(te)));
                        }
                        throw te;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DebugThisCall;
}());
/**
 * The `NativeWrapper` class is a bridge between JS and Native PowerAuth library.
 */
var NativeWrapper = /** @class */ (function () {
    function NativeWrapper() {
    }
    /**
     * Perform call to the native function with given name.
     * @param name Name of function to call.
     * @param instanceId PowerAuth instance identifier.
     * @param args Additional arguments for the function.
     * @returns Promise with return type.
     */
    NativeWrapper.thisCall = function (name, instanceId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return this.thisTrampoline.call(name, instanceId, __spreadArray([], args, true));
    };
    /**
     * Perform call to the native function with given name.
     * @param name Name of function to call.
     * @param instanceId PowerAuth instance identifier.
     * @param args Additional arguments for the function.
     * @returns Promise with optional return type.
     */
    NativeWrapper.thisCallNull = function (name, instanceId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return patchNull(this.thisTrampoline.call(name, instanceId, __spreadArray([], args, true)));
    };
    /**
     * Perform call to the native function with given name, returning bool.
     * @param name Name of function to call.
     * @param instanceId PowerAuth instance identifier.
     * @param args Additional arguments for the function.
     * @returns Promise with boolean type.
     */
    NativeWrapper.thisCallBool = function (name, instanceId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return patchBool(this.thisTrampoline.call(name, instanceId, __spreadArray([], args, true)));
    };
    /**
     * Perform call to the native function with given name. The method is useful in case that
     * call to functions doesn't require instance identifier.
     * @param name Name of function to call.
     * @param args Additional arguments for the function.
     * @returns Promise with type.
     */
    NativeWrapper.staticCall = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.staticTrampoline.call(name, __spreadArray([], args, true));
    };
    /**
     * Perform call to the native function with given name. The method is useful in case that
     * call to functions doesn't require instance identifier.
     * @param name Name of function to call.
     * @param args Additional arguments for the function.
     * @returns Promise with optiomal return type.
     */
    NativeWrapper.staticCallNull = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return patchNull(this.staticTrampoline.call(name, __spreadArray([], args, true)));
    };
    /**
     * Perform call to the native function with given name. The method is useful in case that
     * call to functions doesn't require instance identifier.
     * @param name Name of function to call.
     * @param args Additional arguments for the function.
     * @returns Promise with bool type.
     */
    NativeWrapper.staticCallBool = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return patchBool(this.staticTrampoline.call(name, __spreadArray([], args, true)));
    };
    /**
     * Enable or disable low level debug features. The __DEV__ variable must be true.
     * @param traceFail If true, then detailed log entry about failure will be printed to the console.
     * @param traceCall If true, then each call to native code will be printed with a detailed information.
     */
    NativeWrapper.setDebugFeatures = function (traceFail, traceCall) {
        if (__DEV__) {
            if (traceCall || traceFail) {
                this.thisTrampoline = new DebugThisCall(traceCall, traceFail);
                this.staticTrampoline = new DebugStaticCall(traceCall, traceFail);
            }
            else {
                this.thisTrampoline = new DefaultThisCall();
                this.staticTrampoline = new DefaultStaticCall();
            }
        }
    };
    /**
     * Process any exception reported from the native module and handle platfrom specific cases.
     * The method also validate whether exception parameter is already PowerAuthError type, to prevent
     * double error wrapping.
     *
     * @param exception Exception to process.
     * @param message Optional message.
     * @returns Instance of PowerAuthError.
     */
    NativeWrapper.processException = function (exception, message) {
        if (message === void 0) { message = undefined; }
        // Initial checks:
        // - Check if exception is undefined. That can happen when non-native exception is processed.
        // - Check if the exception is already PowerAuthError type. If so, then return the same instance.
        if (!exception) {
            return new PowerAuthError(undefined, message !== null && message !== void 0 ? message : "Operation failed with unspecified error");
        }
        else if (exception instanceof PowerAuthError) {
            // There's no additional message, we can return exception as it is.
            if (!message) {
                return exception;
            }
            // There's additional message, so wrap PowerAuthError into another PowerAuthError
            return new PowerAuthError(exception, message);
        }
        // Otherwise handle the platform specific cases.
        if (Platform.OS == "android") {
            return this.processAndroidException(exception, message);
        }
        else if (Platform.OS == "ios") {
            return this.processIosException(exception, message);
        }
        else {
            return new PowerAuthError(undefined, "Unsupported platform");
        }
    };
    /**
     * Process iOS specific exception reported from the native module.
     *
     * @param exception Original exception reported from iOS native module.
     * @param message Optional message.
     * @returns Instance of PowerAuthError.
     */
    NativeWrapper.processIosException = function (exception, message) {
        if (message === void 0) { message = undefined; }
        return new PowerAuthError(exception, message);
    };
    /**
     * Process Android specific exception reported from the native module.
     *
     * @param exception Original exception reported from Android native module.
     * @param message Optional message.
     * @returns Instance of PowerAuthError.
     */
    NativeWrapper.processAndroidException = function (exception, message) {
        if (message === void 0) { message = undefined; }
        return new PowerAuthError(exception, message);
    };
    NativeWrapper.staticTrampoline = new DefaultStaticCall();
    NativeWrapper.thisTrampoline = new DefaultThisCall();
    return NativeWrapper;
}());
export { NativeWrapper };
/**
 * Function patch boolean value returned on iOS platform to be always true or false.
 * The reason for this is that on iOS, we marshal BOOL as NSNumber.
 * @param originalPromise Original promise which result needs to be patched.
 * @returns Patched promise that always resolve to true or false.
 */
export function patchBool(originalPromise) {
    if (Platform.OS === 'android') {
        return originalPromise;
    }
    return new Promise(function (resolved, rejected) {
        originalPromise
            .then(function (r) { return resolved(r ? true : false); })
            .catch(function (f) { return rejected(f); });
    });
}
/**
 * Function patch nullable value (e.g. null or undefined) returned from native code to be always undefined.
 * The reason for this is that on iOS, we marshal BOOL as NSNumber.
 * @param originalPromise Original promise which result needs to be patched.
 * @returns Patched promise that always resolve to value or undefined.
 */
export function patchNull(originalPromise) {
    return new Promise(function (resolved, rejected) {
        originalPromise
            .then(function (r) { return resolved(r !== null && r !== void 0 ? r : undefined); })
            .catch(function (f) { return rejected(f); });
    });
}
/**
 * Function translate array of arguments into pretty string.
 * @param fname Function name.
 * @param args Array with arguments.
 * @returns Pretty string created from arguments array.
 */
function prettyArgs(fname, args) {
    switch (fname) {
        case 'changePassword':
        case 'unsafeChangePassword':
            args[1] = args[2] = '***';
            break;
        case 'validatePassword':
        case 'addBiometryFactor':
            args[1] = '***';
            break;
        default:
            break;
    }
    var authIndex = 0;
    if (args[1] instanceof PowerAuthAuthentication) {
        authIndex = 1;
    }
    else if (args[2] instanceof PowerAuthAuthentication) {
        authIndex = 2;
    }
    if (authIndex > 0) {
        var auth = args[authIndex];
        args[authIndex] = {
            password: auth.password ? '***' : undefined,
            biometricPrompt: auth.biometricPrompt,
            isCommit: auth.isCommit,
            isBiometry: auth.isBiometry,
            biometryKeyId: auth.biometryKeyId,
            // deprecated
            usePossession: auth.usePossession,
            useBiometry: auth.useBiometry,
            userPassword: auth.userPassword ? '***' : undefined,
            biometryMessage: auth.biometryMessage,
            biometryTitle: auth.biometryTitle
        };
    }
    var v = JSON.stringify(args);
    return v.slice(1, v.length - 1);
}
