/*
 * Copyright 2022 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PowerAuthError, PowerAuthErrorCode } from "../index";
import { NativePassphraseMeter } from "../internal/NativePassphraseMeter";
import { NativePassword } from "../internal/NativePassword";
import { NativeWrapper } from "../internal/NativeWrapper";
/**
 * The `PowerAuthPassword` class implements safe storage for users' passwords.
 * The class is using an underlying native object to store the user's password securely
 * in the memory. The goal is to keep the user's password in the memory for as short
 * as possible time. To achieve this, the native object implements the following
 * precautions:
 *
 * - If it's constructed with `destroyOnUse` parameter set to `true` then the native
 *   password is automatically destroyed after it's used for the cryptographic operation.
 *
 * - If it's constructed with `powerAuthInstanceId` then the native object will be
 *   destroyed after the `PowerAuth` class with the same identifier is deconfigured.
 *
 * - If you leave the instance of `PowerAuthPassword` class as it is, then the native
 *   password is removed from the memory after 5 minutes of inactivity. The JavaScript
 *   object is still functional, so if you use any API function, then the native
 *   password is re-initialized, but the previous passphrase is lost. You can provide
 *   an optional `onAutomaticCleanup` function to the object's constructor to detect
 *   the such situation.
 *
 * - If you call any `PowerAuthPassword` method except `release()`, then the auto-cleanup
 *   timer is reset, so the native password will live for another 5 minutes.
 *
 * Be aware that this class is effective only if you're using a numeric PIN for the passphrase
 * although its API accepts full Unicode code point at the input. This is because it's quite
 * simple to re-implement the PIN keyboard with your custom UI components. On opposite to that,
 * for the full alphanumeric input, you need to use the system keyboard, which leaves traces
 * of the user's password in memory.
 *
 * If you're interested in more detail about why the passwords should be protected in
 * the memory, then you can follow the [Working with passwords securely](https://developers.wultra.com/components/powerauth-mobile-sdk/1.7.x/documentation/PowerAuth-SDK-for-iOS#working-with-passwords-securely)
 * chapter from the PowerAuth mobile SDK.
 */
var PowerAuthPassword = /** @class */ (function () {
    /**
     * Construct password object and specify whether it's re-usable and/or should be destroyed
     * together with the owning PowerAuth class instance.
     * @param destroyOnUse If `true` then the native password is destroyed after is used for the cryptograhic operation.
     * @param onAutomaticCleanup If provided, then the closure is called when the native password is restored and the previous content is lost.
     * @param powerAuthInstanceId If specified, then the native password will be destroyed together with PowerAuth instance.
     * @param autoreleaseTime Autorelease timeout in milliseconds. The value is used only for the testing purposes, and is ignored in the release build of library.
     */
    function PowerAuthPassword(destroyOnUse, onAutomaticCleanup, powerAuthInstanceId, autoreleaseTime) {
        if (destroyOnUse === void 0) { destroyOnUse = true; }
        if (onAutomaticCleanup === void 0) { onAutomaticCleanup = undefined; }
        if (powerAuthInstanceId === void 0) { powerAuthInstanceId = undefined; }
        if (autoreleaseTime === void 0) { autoreleaseTime = 0; }
        this.destroyOnUse = destroyOnUse;
        this.powerAuthInstanceId = powerAuthInstanceId;
        this.autoreleaseTime = autoreleaseTime;
        this.onAutomaticCleanup = onAutomaticCleanup;
    }
    /**
     * Return number of characters stored in the pasword. This method also
     * extends the lifetime of the underlying native password.
     *
     * @returns Number of characters stored in the password.
     */
    PowerAuthPassword.prototype.length = function () {
        return this.withObjectId(function (id) { return NativePassword.length(id); });
    };
    /**
     * Clear content of the password.
     */
    PowerAuthPassword.prototype.clear = function () {
        return this.withObjectId(function (id) { return NativePassword.clear(id); });
    };
    /**
     * Determine whether the stored password is empty. This method also
     * extends the lifetime of the underlying native password.
     *
     * @returns `true` if password is empty.
     */
    PowerAuthPassword.prototype.isEmpty = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.length()];
                    case 1: return [2 /*return*/, (_a.sent()) == 0];
                }
            });
        });
    };
    /**
     * Append character to the end of the password. This method also
     * extends the lifetime of the underlying native password.
     *
     * @param character Character to add at the end of password.
     * @returns Number of characters stored in the password.
     */
    PowerAuthPassword.prototype.addCharacter = function (character) {
        return this.withObjectId(function (id) { return NativePassword.addCharacter(id, getCodePoint(character)); });
    };
    /**
     * Insert character at the specified position. This method also
     * extends the lifetime of the underlying native password.
     *
     * @param character Character to insert.
     * @param at Position where character will be inserted. Must be in range 0, upt to length, otherwise `PowerAuthErrorCode.WRONG_PARAMETER` error is reported.
     * @returns Number of characters stored in the password.
     */
    PowerAuthPassword.prototype.insertCharacter = function (character, at) {
        return this.withObjectId(function (id) { return NativePassword.insertCharacter(id, getCodePoint(character), at); });
    };
    /**
     * Remove character at the specified position. This method also
     * extends the lifetime of the underlying native password.
     *
     * @param position Position of character to be removed. Must be in range 0 up to length - 1, otherwise `PowerAuthErrorCode.WRONG_PARAMETER` error is reported.
     * @returns Remaining number of characters stored in the password.
     */
    PowerAuthPassword.prototype.removeCharacterAt = function (position) {
        return this.withObjectId(function (id) { return NativePassword.removeCharacter(id, position); });
    };
    /**
     * Remove last character and return the number of remaining characters stored in the password.
     * This method also extends the lifetime of the underlying native password.
     *
     * @returns Remaining number of characters stored in the password.
     */
    PowerAuthPassword.prototype.removeLastCharacter = function () {
        return this.withObjectId(function (id) { return NativePassword.removeLastCharacter(id); });
    };
    /**
     * Compare two passwords. This method also extends the lifetime of the both underlying
     * native passwords.
     *
     * @param password Password to compare with this instance.
     * @returns true if both passwords contains an equal passphrase.
     */
    PowerAuthPassword.prototype.isEqualTo = function (password) {
        return this.withObjectId(function (id1) { return password.withObjectId(function (id2) { return NativePassword.isEqual(id1, id2); }); });
    };
    /**
     * Release the underlying native password object.
     */
    PowerAuthPassword.prototype.release = function () {
        if (this.passwordObjectId) {
            var objId = this.passwordObjectId;
            this.passwordObjectId = undefined;
            return NativePassword.release(objId);
        }
        return Promise.resolve();
    };
    /**
     * If object contains numeric digits only, then you can test the streingt of PIN
     * stored in the object.
     * @returns `PinTestResult` object.
     * @throws `PowerAuthErrorCode.WRONG_PARAM` if PIN contains other characters than digits or its length is less than 4.
     */
    PowerAuthPassword.prototype.testPinStrength = function () {
        return this.withObjectId(function (id) { return NativePassphraseMeter.testPin({ passwordObjectId: id }); });
    };
    /**
     * Acquire native password object ID and execute action with this identifier.
     * @param action Action to execute after objectId is acquired.
     * @param recoveringFromError If true, then this is 2nd attempt to execute action after recovery from action.
     * @returns Promise result type.
     */
    PowerAuthPassword.prototype.withObjectId = function (action, recoveringFromError) {
        if (recoveringFromError === void 0) { recoveringFromError = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!!this.passwordObjectId) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, NativePassword.initialize(this.destroyOnUse, this.powerAuthInstanceId, this.autoreleaseTime)];
                    case 1:
                        _a.passwordObjectId = _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, action(this.passwordObjectId)];
                    case 3: return [2 /*return*/, _b.sent()];
                    case 4:
                        error_1 = _b.sent();
                        if (!recoveringFromError && error_1.code == PowerAuthErrorCode.INVALID_NATIVE_OBJECT) {
                            // Underlying native password has been already destroyed.
                            // We can recover from this situation by resetting identifier and retry the operation
                            this.passwordObjectId = undefined;
                            // Call cleanup callback if it's defined
                            if (this.onAutomaticCleanup != undefined) {
                                this.onAutomaticCleanup();
                            }
                            // Now try to repeat action.
                            return [2 /*return*/, this.withObjectId(action, true)];
                        }
                        // Otherwise simply process the exception and report the error.
                        throw NativeWrapper.processException(error_1);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Convert this password object into RawPassword object that can be passed safely to a native call.
     * @returns Frozen RawPassword object.
     */
    PowerAuthPassword.prototype.toRawPassword = function () {
        return this.withObjectId(function (id) { return Promise.resolve(Object.freeze({ passwordObjectId: id })); });
    };
    return PowerAuthPassword;
}());
export { PowerAuthPassword };
/**
 * Function translate string or number into unicode code point. If string parameter is provided,
 * then the `codePointAt(0)` is returned.
 * @param character CharacterType to translate.
 * @returns number with Unicode Code Point.
 */
function getCodePoint(character) {
    var c;
    if (typeof character === 'string') {
        if (character.length === 0) {
            throw new PowerAuthError(undefined, "String is empty", PowerAuthErrorCode.WRONG_PARAMETER);
        }
        var cp = character.codePointAt(0);
        if (cp === undefined) {
            throw new PowerAuthError(undefined, "Failed to extract 1st. code point", PowerAuthErrorCode.WRONG_PARAMETER);
        }
        c = cp;
    }
    else {
        c = character;
    }
    return c;
}
