/*
 * Copyright 2022 Wultra s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NativePassphraseMeter } from './internal/NativePassphraseMeter';
import { NativeWrapper } from "./internal/NativeWrapper";
/**
 * Issues found during the PIN strength test.
 */
export var PinTestIssue;
(function (PinTestIssue) {
    /**
     * Not enough unique digits found.
     */
    PinTestIssue["NOT_UNIQUE"] = "NOT_UNIQUE";
    /**
     * Too much repeating characters.
     */
    PinTestIssue["REPEATING_CHARS"] = "REPEATING_CHARS";
    /**
     * There is a pattern in this pin (for example 1357)
     */
    PinTestIssue["PATTERN_FOUND"] = "PATTERN_FOUND";
    /**
     * Tested pin could be date (for example 2512 as birthday - 25th of december)
     */
    PinTestIssue["POSSIBLY_DATE"] = "POSSIBLY_DATE";
    /**
     * Tested pin is in TOP used pins (like 1234 as number 1 used pin).
     */
    PinTestIssue["FREQUENTLY_USED"] = "FREQUENTLY_USED";
})(PinTestIssue || (PinTestIssue = {}));
/**
 * Object providing interface to test strength of PIN or password. Right now, only
 * the function for PIN testing is provided.
 */
var PowerAuthPassphraseMeter = /** @class */ (function () {
    function PowerAuthPassphraseMeter() {
    }
    /**
     * Test strength of PIN.
     * @param pin PIN to test. You can provide string or `PowerAuthPassword` object containing numbers only.
     * @returns `PinTestResult` object.
     * @throws `PowerAuthErrorCode.WRONG_PARAM` if PIN contains other characters than digits or its length is less than 4.
     */
    PowerAuthPassphraseMeter.testPin = function (pin) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, , 6]);
                        _b = (_a = NativePassphraseMeter).testPin;
                        if (!(typeof pin == 'string')) return [3 /*break*/, 1];
                        _c = pin;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, pin.toRawPassword()];
                    case 2:
                        _c = _d.sent();
                        _d.label = 3;
                    case 3: return [4 /*yield*/, _b.apply(_a, [_c])];
                    case 4: return [2 /*return*/, _d.sent()];
                    case 5:
                        error_1 = _d.sent();
                        throw NativeWrapper.processException(error_1);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return PowerAuthPassphraseMeter;
}());
export { PowerAuthPassphraseMeter };
